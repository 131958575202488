<template>
  <div id="materialReagent">
    <el-dialog
      :title="materialReagentFormTitle"
      width="1500px"
      :visible.sync="materialReagentDialogVisible"
      :close-on-click-modal="false"
      @close="materialReagentDialogClose"
    >
      <el-form
        ref="materialReagentFormRef"
        :model="materialReagentForm"
        :rules="materialReagentFormRules"
        label-position="right"
        label-width="150px"
      >
        <el-row>
          <el-col :span="8">
            <el-form-item label="试剂试药名称" prop="reagentName">
              <el-input v-model="materialReagentForm.reagentName" placeholder="请输入试剂试药名称" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="型号规格" prop="spec">
              <el-input v-model="materialReagentForm.spec" placeholder="请输入型号规格" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="批号" prop="batchNo">
              <el-input v-model="materialReagentForm.batchNo" placeholder="请输入批号" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="数量" prop="quantity">
              <el-input v-model="materialReagentForm.quantity" placeholder="请输入数量" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="厂家" prop="manufactor">
              <el-input v-model="materialReagentForm.manufactor" placeholder="请输入厂家" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="到货日期" prop="arrivalDate">
              <el-date-picker v-model="materialReagentForm.arrivalDate" placeholder="请选择到货日期" value-format="yyyy-MM-dd" />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="有效期" prop="validDate">
              <el-date-picker v-model="materialReagentForm.validDate" placeholder="请选择有效期" value-format="yyyy-MM-dd" />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="所在位置" prop="location">
              <el-input v-model="materialReagentForm.location" placeholder="请输入所在位置" clearable />
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item label="出入库情况">
          <vxe-toolbar
            v-if="materialReagentFormTitle === '新增试剂试药管理'
              || materialReagentFormTitle === '修改试剂试药管理'"
          >
            <template #buttons>
              <vxe-button @click="insertRowEvent()">
                新增
              </vxe-button>
            </template>
          </vxe-toolbar>
          <vxe-table
            ref="xTable"
            border
            resizable
            show-overflow
            keep-source
            :data="detailList"
            :edit-config="{trigger: 'click', mode: 'cell'}"
          >
            <vxe-table-column field="outInSituation" title="出入库情况" width="150">
              <template v-slot="{row,column}">
                <vxe-radio-group v-model="row[column.property]">
                  <vxe-radio :label="1" content="出库" />
                  <vxe-radio :label="2" content="入库" />
                </vxe-radio-group>
              </template>
            </vxe-table-column>
            <vxe-table-column
              field="applyDept"
              title="申请部门"
              :edit-render="{name: '$input', props: {clearable: true}}"
            />
            <vxe-table-column
              field="operator"
              title="操作人"
              :edit-render="{name: '$input', props: {clearable: true}}"
            />
            <vxe-table-column
              field="situationExplain"
              title="情况说明"
              :edit-render="{name: '$input', props: {clearable: true}}"
            />
            <vxe-table-column
              field="outInQuantity"
              title="出入库数量"
              :edit-render="{name: '$input', props: {clearable: true}}"
            />
            <vxe-table-column
              field="balanceQuantity"
              title="结余数量"
              :edit-render="{name: '$input', props: {clearable: true}}"
            />
            <vxe-table-column
              field="outInFormNo"
              title="出入库申请单编号"
              :edit-render="{name: '$input', props: {clearable: true}}"
            />
            <vxe-table-column
              field="remarks"
              title="备注"
              :edit-render="{name: '$input', props: {clearable: true}}"
            />
            <vxe-table-column
              v-if="materialReagentFormTitle === '新增试剂试药管理'
                || materialReagentFormTitle === '修改试剂试药管理'"
              title="操作"
              width="100"
            >
              <template #default="{ row }">
                <template>
                  <vxe-button @click="removeRowEvent(row)">
                    删除
                  </vxe-button>
                </template>
              </template>
            </vxe-table-column>
          </vxe-table>
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button @click="materialReagentDialogVisible = false">
          取 消
        </el-button>
        <el-button type="primary" @click="materialReagentFormSubmit">
          确 定
        </el-button>
      </div>
    </el-dialog>
    <el-form inline size="small">
      <el-form-item label="试剂试药名称">
        <el-input v-model="searchForm.reagentName" placeholder="请输入试剂试药名称" clearable />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" @click="handleSearch">
          搜索
        </el-button>
        <el-button
          v-if="checkPermission(['CC_MATERIAL_REAGENT_ADD'])"
          type="primary"
          icon="el-icon-plus"
          @click="handleAdd"
        >
          新增
        </el-button>
      </el-form-item>
    </el-form>
    <el-table
      :data="materialReagentPage.list"
      row-key="id"
      :cell-style="{padding: '6px'}"
      :header-cell-style="{background: '#f8f8f9'}"
      :height="getTableHeight()"
    >
      <el-table-column prop="reagentName" label="试剂试药名称" />
      <el-table-column prop="spec" label="型号规格" />
      <el-table-column prop="batchNo" label="批号" />
      <el-table-column prop="quantity" label="数量" />
      <el-table-column prop="manufactor" label="厂家" />
      <el-table-column label="到货日期">
        <template slot-scope="scope">
          <span v-if="scope.row.arrivalDate">{{ scope.row.arrivalDate.substring(0, 10) }}</span>
        </template>
      </el-table-column>
      <el-table-column label="有效期">
        <template slot-scope="scope">
          <span v-if="scope.row.validDate">{{ scope.row.validDate.substring(0, 10) }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="location" label="所在位置" />
      <el-table-column
        label="操作"
        align="center"
        width="200"
        fixed="right"
      >
        <template slot-scope="scope">
          <el-button
            v-if="checkPermission(['CC_MATERIAL_REAGENT_DELETE'])"
            type="text"
            icon="el-icon-delete"
            size="small"
            @click.stop="handleDelete(scope.$index, scope.row)"
          >
            删除
          </el-button>
          <el-button
            v-if="checkPermission(['CC_MATERIAL_REAGENT_UPDATE'])"
            type="text"
            icon="el-icon-edit"
            size="small"
            @click.stop="handleUpdate(scope.$index, scope.row)"
          >
            修改
          </el-button>
          <el-button
            type="text"
            icon="el-icon-info"
            size="small"
            @click.stop="handleInfo(scope.$index, scope.row)"
          >
            详情
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :total="materialReagentPage.total"
      :current-page="searchForm.pageNum"
      :page-size="searchForm.pageSize"
      :page-sizes="[10, 15, 20]"
      layout="total, sizes, prev, pager, next, jumper"
      background
      @current-change="pageNumChange"
      @size-change="pageSizeChange"
    />
  </div>
</template>

<script>
import {
  addMaterialReagent,
  deleteMaterialReagent,
  updateMaterialReagent,
  selectMaterialReagentInfo,
  selectMaterialReagentList
} from '@/api/storage/materialReagent'

export default {
  data () {
    return {
      materialReagentDialogVisible: false,
      materialReagentFormTitle: '',
      materialReagentForm: {
        id: '',
        reagentName: '',
        spec: '',
        batchNo: '',
        quantity: '',
        manufactor: '',
        arrivalDate: '',
        validDate: '',
        location: '',
        detailJson: ''
      },
      materialReagentFormRules: {
        reagentName: [{ required: true, message: '试剂试药名称不能为空', trigger: ['blur', 'change']}]
      },
      materialReagentPage: {
        list: [],
        total: 0
      },
      searchForm: {
        pageNum: 1,
        pageSize: 10,
        reagentName: ''
      },
      detailList: []
    }
  },
  created () {
    selectMaterialReagentList(this.searchForm).then(res => {
      this.materialReagentPage = res
    })
  },
  methods: {
    materialReagentDialogClose () {
      this.$refs.materialReagentFormRef.resetFields()
      this.detailList = []
    },
    materialReagentFormSubmit () {
      if (this.materialReagentFormTitle === '试剂试药管理详情') {
        this.materialReagentDialogVisible = false
        return
      }
      this.$refs.materialReagentFormRef.validate(async valid => {
        if (valid) {
          this.materialReagentForm.detailJson = JSON.stringify(this.$refs.xTable.getTableData().tableData)
          if (this.materialReagentFormTitle === '新增试剂试药管理') {
            await addMaterialReagent(this.materialReagentForm)
          } else if (this.materialReagentFormTitle === '修改试剂试药管理') {
            await updateMaterialReagent(this.materialReagentForm)
          }
          this.materialReagentPage = await selectMaterialReagentList(this.searchForm)
          this.materialReagentDialogVisible = false
        }
      })
    },
    handleAdd () {
      this.materialReagentFormTitle = '新增试剂试药管理'
      this.materialReagentDialogVisible = true
    },
    handleDelete (index, row) {
      this.$confirm('确认删除？', '提示', {
        type: 'warning'
      }).then(async () => {
        await deleteMaterialReagent(row.id)
        if (this.materialReagentPage.list.length === 1 && this.searchForm.pageNum > 1) {
          this.searchForm.pageNum--
        }
        this.materialReagentPage = await selectMaterialReagentList(this.searchForm)
      })
    },
    handleUpdate (index, row) {
      this.materialReagentFormTitle = '修改试剂试药管理'
      this.materialReagentDialogVisible = true
      this.selectMaterialReagentInfoById(row.id)
    },
    handleInfo (index, row) {
      this.materialReagentFormTitle = '试剂试药管理详情'
      this.materialReagentDialogVisible = true
      this.selectMaterialReagentInfoById(row.id)
    },
    selectMaterialReagentInfoById (id) {
      selectMaterialReagentInfo(id).then(res => {
        this.materialReagentForm.id = res.id
        this.materialReagentForm.reagentName = res.reagentName
        this.materialReagentForm.spec = res.spec
        this.materialReagentForm.batchNo = res.batchNo
        this.materialReagentForm.quantity = res.quantity
        this.materialReagentForm.manufactor = res.manufactor
        this.materialReagentForm.arrivalDate = res.arrivalDate
        this.materialReagentForm.validDate = res.validDate
        this.materialReagentForm.location = res.location
        this.detailList = res.detailList
      })
    },
    handleSearch () {
      this.searchForm.pageNum = 1
      selectMaterialReagentList(this.searchForm).then(res => {
        this.materialReagentPage = res
      })
    },
    pageNumChange (pageNum) {
      this.searchForm.pageNum = pageNum
      selectMaterialReagentList(this.searchForm).then(res => {
        this.materialReagentPage = res
      })
    },
    pageSizeChange (pageSize) {
      this.searchForm.pageSize = pageSize
      this.searchForm.pageNum = 1
      selectMaterialReagentList(this.searchForm).then(res => {
        this.materialReagentPage = res
      })
    },
    async insertRowEvent (row) {
      let { row: newRow } = await this.$refs.xTable.insertAt({}, -1)
      await this.$refs.xTable.setActiveRow(newRow)
    },
    removeRowEvent (row) {
      this.$refs.xTable.remove(row)
    }
  }
}
</script>

<style>
</style>
